import localforage from 'localforage'

const getDefaultMetadata = () => {
	return {
		// Project name
		title: ""
	}
}

const state = {
	metadata: getDefaultMetadata()
}

const getters = {
	// Get the title
	getTitle: state => {
		return state.metadata.title;
	}
}

const mutations = {
	reset(state) {
		state.metadata = getDefaultMetadata();
	},
	// Set metadata
	setMetadata(state, data) {
		// Set metadata
		state.metadata = data;
		// Update local storage
		localforage.setItem("metadata", state.metadata);
	},
	// Set title
	setTitle(state, title) {
		// Set title
		state.metadata.title = title;
		// Update local storage
		localforage.setItem("metadata", state.metadata);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}