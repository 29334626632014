<template>
	<div>
		<div class="accordion" role="tablist" style="height: 300px">
			<!-- LOADED -->
			<div>
				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-0" role="tab">
						<b-button class="no-border text-left" block v-b-toggle.accordion-0 variant="secondary">
							<span class="when-closed"><BIconCaretRight /></span>
							<span class="when-opened"><BIconCaretDown /></span>
							Loaded with Lens.org ({{ refs.input.loaded.length }})
						</b-button>
					</b-card-header>
					<b-collapse class="ref-area" id="accordion-0" accordion="my-accordion" role="tabpanel">
						<RefsTable :refs="refs.input.loaded" />
					</b-collapse>
				</b-card>
			</div>

			<!-- MANUAL -->
			<div>
				<b-card no-body class="mb-1">
					<b-card-header header-tag="header" class="p-0" role="tab">
						<b-button class="no-border text-left" block v-b-toggle.accordion-1 variant="secondary">
							<span class="when-closed"><BIconCaretRight /></span>
							<span class="when-opened"><BIconCaretDown /></span>
							Citations to be searched manually ({{ refs.input.manual.length }})
						</b-button>
					</b-card-header>
					<b-collapse class="ref-area" id="accordion-1" accordion="my-accordion" role="tabpanel">
						<RefsTable :refs="refs.input.manual" />
					</b-collapse>
				</b-card>
			</div>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import formatMixin from '../mixins/formatMixin'
import serverMixin from '../mixins/serverMixin'

import RefsTable from './RefsTable.vue'

export default {
	name: "RefsInputAccordion",
	mixins: [
		formatMixin,
		serverMixin
	],
	components: {
		RefsTable
	},
	computed: {
		...mapGetters({
			refs: 'references/getRefs'
		})
	}
}
</script>

<style>
.no-border:focus, .no-border:active {
	outline: none !important;
	box-shadow: none !important;
}
.ref-area {
	max-height: calc(100vh - 220px) !important;
	overflow: auto;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
		display: none;
}
</style>