import Vue from 'vue'
import Vuex from 'vuex'
import references from './modules/references'
import metadata from './modules/metadata'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		metadata,
		references
	},
	actions: {
		resetStore({ commit }) {
			commit('metadata/reset');
			commit('references/reset');
		}
	}
})