<template>
	<div>
		<div class="d-flex justify-content-center" v-if="isLoading">
			<b-spinner class="m-3"></b-spinner>
			<div class="m-3">Loading...</div>
		</div>
		<table v-else id="refs" style="width:100%">
			<tr>
				<th width="24%">Title</th>
				<th width="24%">Authors</th>
				<th width="24%">Journal</th>
				<th width="7%">Volume</th>
				<th width="7%">Pages</th>
			</tr>
			<tr v-for="(ref, j) of references" @dblclick="editReference(ref)" :key="j">
				<td>{{ ref.title }}</td>
				<td>{{ formatAuthors(ref.authors) }}</td>
				<td>{{ ref.journal }}</td>
				<td>{{ ref.volume }}</td>
				<td>{{ ref.pages }}</td>
			</tr>
		</table>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import serverMixin from '../mixins/serverMixin';
import formatMixin from '../mixins/formatMixin';

export default {
	name: "RefsTableLens",
	props: {
		categoryKey: String,
		currentPage: Number,
		perPage: Number
	},
	mixins: [
		serverMixin,
		formatMixin
	],
	mounted() {
		console.log("Initializing references...")
		this.loadReferences();
	},
	computed: {
		...mapGetters({
			getRefs: 'references/getRefs'
		}),
		refs() {
			return this.getRefs.output[this.categoryKey].slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
		}
	},
	data() {
		return {
			isLoading: false,
			references: []
		}
	},
	watch: {
		refs() {
			this.loadReferences();
		},
		isLoading(newVal) {
			this.$emit("loading", newVal);
		}
	},
	methods: {
		clearSelection() {
			if(document.selection && document.selection.empty) {
					document.selection.empty();
			} else if(window.getSelection) {
					var sel = window.getSelection();
					sel.removeAllRanges();
			}
		},
		editReference(item) {
			this.clearSelection();
			if (item) {
				this.$root.$emit('show-edit', item);
			} else {
				console.log("No reference found");
			}
		},
		loadReferences() {
			this.isLoading = true;
			this.getReferences(this.refs)
				.then(references => {
					this.references = references;
					this.isLoading = false;
				})
				.catch(err => {
					alert(err);
					console.error(err);
					this.references = [];
					this.isLoading = false;
				});
		}
	}
}
</script>

<style scoped>
#refs {
	border-collapse: collapse;
	width: 100%;
	background-color: ""
}

#refs td, #refs th {
	border: 1px solid #ddd;
	padding: 8px;
}

#refs tr {background-color: #f2f2f2;}

#refs tr:hover {background-color: #ddd;}

#refs th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: #438eb9;
	color: white;
}
</style>