<template>
	<div class="d-flex">
		<b-pagination
			:value="value"
			@input="$emit('input', $event)"
			:total-rows="totalRows"
			:per-page="perPage"
			:disabled="disabled"
		></b-pagination>
		<div class="mt-2 ml-3">
			{{
				startIndex + 1
			}}-{{
				Math.min(endIndex, totalRows)
			}} of {{
				totalRows
			}} References
		</div>
	</div>
</template>

<script>
import { BPagination } from '@iebh/bootstrap-vue';
export default {
	components: {
		BPagination
	},
	props: {
		value: Number,
		totalRows: Number,
		perPage: Number,
		disabled: Boolean,
	},
	computed: {
		startIndex() {
			return (this.value - 1) * this.perPage;
		},
		endIndex() {
			return this.value * this.perPage
		}
	}
}
</script>
