<template>
	<table id="refs" style="width:100%">
			<tr>
				<th width="24%">Title</th>
				<th width="24%">Authors</th>
				<th width="24%">Journal</th>
				<th width="7%">Volume</th>
				<th width="7%">Pages</th>
			</tr>
			<tr v-for="(ref, j) of refs" @dblclick="editReference(ref)" :key="j">
				<td>{{ ref.title }}</td>
				<td>{{ formatAuthors(ref.authors) }}</td>
				<td>{{ ref.journal }}</td>
				<td>{{ ref.volume }}</td>
				<td>{{ ref.pages }}</td>
			</tr>
		</table>
</template>

<script>
import formatMixin from '../mixins/formatMixin';

export default {
	name: "RefsTable",
	props: {
		refs: Array
	},
	mixins: [
		formatMixin
	],
	methods: {
		clearSelection() {
			if(document.selection && document.selection.empty) {
					document.selection.empty();
			} else if(window.getSelection) {
					var sel = window.getSelection();
					sel.removeAllRanges();
			}
		},
		editReference(item) {
			this.clearSelection();
			if (item) {
				this.$root.$emit('show-edit', item);
			} else {
				console.log("No reference found");
			}
		}
	}
}
</script>

<style scoped>
#refs {
	border-collapse: collapse;
	width: 100%;
	background-color: ""
}

#refs td, #refs th {
	border: 1px solid #ddd;
	padding: 8px;
}

#refs tr {background-color: #f2f2f2;}

#refs tr:hover {background-color: #ddd;}

#refs th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: #438eb9;
	color: white;
}
</style>