import localforage from 'localforage'

const state = {
	// Stores duplicates in 2d array
	refs: null,
}

const getters = {
	// Get the entire dupes array
	getRefs: state => {
		return state.refs;
	},
}

const mutations = {
	reset(state) {
		state.refs = null;
	},
	// Set all duplicates
	setRefs(state, obj) {
		// Set dupes
		state.refs = obj;
		// Update local storage
		localforage.setItem("refs", state.refs);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}